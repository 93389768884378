import React from 'react'
import { Link } from 'react-router-dom';
import Logo from './../assets/logo.png';

function Header() {
    return (
        <>
            <div class="header-top">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                            <div class="contact-info">
                                <i class="las la-map-marker"></i> Jos, Nigeria
                                <i class="las la-envelope"></i> info@segullahrecycling.ng
                                <i class="las la-phone"></i> 07000000000
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 text-end">
                            <div class="header_top_right">
                                <div class="social-area">
                                    <a href="https://www.facebook.com/share/KZiTwwfECxTPX3PF/?mibextid=qi2Omg"><i class="lab la-facebook-f"></i></a>
                                    <a href="https://youtube.com/@segullah-h5i?si=ZD9rUoufA8shEyZ3"><i class="lab la-youtube"></i></a>
                                    <a href="https://x.com/segullahgreenf?t=vgz1391tFOdHSHij0UvzlA&s=08"><i class="lab la-twitter"></i></a>
                                    <a href="https://www.instagram.com/invites/contact/?igsh=1n15hmcku93q1&utm_content=v85cqiy"><i class="lab la-instagram"></i></a>
                                    <a href="https://www.linkedin.com/company/segullah-green-foundation/"><i class="lab la-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="header-area">
                <div class="sticky-area">
                    <div class="navigation">
                        <div class="container-fluid">
                            <div class="header-inner-box">
                                <div class="logo">
                                    <Link class="navbar-brand" to="/"><img src={Logo} style={{height: "60px"}} alt="" /></Link>
                                </div>

                                <div class="main-menu">
                                    <nav class="navbar navbar-expand-lg">
                                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                            <span class="navbar-toggler-icon"></span>
                                        </button>

                                        <div class="collapse navbar-collapse justify-content-center"
                                            id="navbarSupportedContent">
                                            <ul class="navbar-nav m-auto">
                                                <li class="nav-item">
                                                    <Link class="nav-link active" to="/">Home</Link>
                                                </li>
                                                {/* <li class="nav-item">
                                                    <Link class="nav-link" to="#">Products</Link>
                                                </li> */}
                                                <li class="nav-item">
                                                    <Link class="nav-link" to="/about">About Us</Link>
                                                </li>
                                                {/* <li class="nav-item">
                                                    <Link class="nav-link" to="#">Blog</Link>
                                                </li> */}

                                                <li class="nav-item">
                                                    <Link class="nav-link" to="/green-initiative">Segullah Foundation</Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link class="nav-link" to="/contact">Contact</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>

                                <div class="header-btn">
                                    <Link to="#" class="main-btn primary">Get a Quote</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import Image5 from './../assets/img5.jpeg'

function AboutPage() {
    return (
        <>
            <Header />
            <div class="breadcroumb-area bread-bg">
                <div class="overlay-2"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breadcroumb-title text-center">
                                <h1>About</h1>
                                <h6><Link to="/">Home</Link> / About</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="about-area section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6 wow fadeInUp" data-wow-delay=".2s">
                            <div class="about-content-wrap">
                                <div class="section-title">
                                    <h2>Vision</h2>
                                </div>
                                <div class="about-content">
                                    <div class="row">
                                        <div class="col-12 col-lg-12">
                                            <div class="about-content-left">
                                                <p class="highlight">
                                                    We envision a future where plastic waste is no longer a burden but a valuable resource. Through responsible PET bottle recycling, we strive to create a cleaner environment, a more sustainable future, and a thriving circular economy for Plateau State.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 wow fadeInUp" data-wow-delay=".2s">
                            <div class="about-content-wrap">
                                <div class="section-title">
                                    <h2>Mission</h2>
                                </div>
                                <div class="about-content">
                                    <div class="row">
                                        <div class="col-12 col-lg-12">
                                            <div class="about-content-left">
                                                <p class="highlight">
                                                    Segullah Recycling Limited is a recycling company dedicated to transforming plastic waste into valuable resources. We focus on collecting, sorting, and recycling Polyethylene Terephthalate (PET) bottles, diverting them from landfills and promoting a circular economy in Plateau State, Nigeria.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="service-area sky-bg section-padding">
                <div class="container">
                    <div class="section-title">
                        <h2>Values</h2>
                    </div>
                    <div class="row">
                        <ul>
                            <li style={{ display: 'block' }}>
                                <div class="text">
                                    <p>1. <strong>Environmental Responsibility:</strong> We are committed to minimizing plastic pollution and its impact on our planet.</p>
                                </div>
                            </li>
                            <li style={{ display: 'block' }}>
                                <div class="text">
                                    <p>2. <strong>Resource Recovery:</strong> We believe in giving discarded plastic a new life by transforming it into valuable PET granules for further manufacturing.</p>
                                </div>
                            </li>
                            <li style={{ display: 'block' }}>
                                <div class="text">
                                    <p>3. <strong>Efficiency and Innovation:</strong> We continuously strive to improve our recycling processes, maximizing efficiency and minimizing environmental footprint.</p>
                                </div>
                            </li>
                            <li style={{ display: 'block' }}>
                                <div class="text">
                                    <p>4. <strong>Community Partnership:</strong> We collaborate with waste collectors, communities, and like-minded organizations to build a robust recycling ecosystem.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="process-area dark-bg section-padding">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-6 col-lg-6">
                            <div class="section-title">
                                <h2 class="text-white">Our Work</h2>
                            </div>
                        </div>
                    </div>
                    <div class="process-item-wrap">
                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".2s">
                                <div class="process-single">
                                    <div class="process-icon">
                                        <img src="/assets/img/process/1.png" alt="" />
                                    </div>
                                    <div class="process-title">
                                        <h5>PET Bottle Collection</h5>
                                    </div>
                                    <div class="process-desc">
                                        <p>We establish partnerships with waste collectors and designated drop-off points to ensure a steady supply of used PET bottles.
                                            <br /><br /><br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".4s">
                                <div class="process-single two">
                                    <div class="process-icon">
                                        <img src="/assets/img/process/2.png" alt="" />
                                    </div>
                                    <div class="process-title">
                                        <h5>Sorting and Processing</h5>
                                    </div>
                                    <div class="process-desc">
                                        <p>Our facility utilizes advanced sorting technology to efficiently separate PET bottles from other recyclables.
                                            <br /><br /><br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".6s">
                                <div class="process-single three">
                                    <div class="process-icon">
                                        <img src="/assets/img/process/3.png" alt="" />
                                    </div>
                                    <div class="process-title">
                                        <h5>PET Recycling</h5>
                                    </div>
                                    <div class="process-desc">
                                        <p>We transform collected PET bottles into high-quality PET granules or pellets through a state-of-the-art recycling process.
                                            <br /><br /><br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".8s">
                                <div class="process-single four">
                                    <div class="process-icon">
                                        <img src="/assets/img/process/4.png" alt="" />
                                    </div>
                                    <div class="process-title">
                                        <h5>Product Manufacturing Potential</h5>
                                    </div>
                                    <div class="process-desc">
                                        <p>4.Recycled PET granules can be used to manufacture various new products, including clothing fibers, food packaging, and even building materials.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="choose_us section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 wow fadeInUp" data-wow-delay=".4s">
                            <div class="choose_us_right">
                                <div class="section-title text-left">
                                    <h2>Connection to Segullah Green Initiatives</h2>
                                </div>
                                <p class="choose_us_right-text">
                                    Segullah Recycling Limited is a partner organization with Segullah Green Initiatives (SGI). We share a common goal of environmental sustainability and believe in a multi-pronged approach. SGI focuses on education and awareness, while SRL tackles the plastic waste issue through responsible recycling. Together, we aim to create a circular economy for PET plastic waste.
                                    <br />
                                    <strong>We invite you to be a Part of the Solution</strong>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AboutPage
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

function ContactUsPage() {
    return (
        <>
            <Header />
            <div class="breadcroumb-area contact-bg">
                <div class="overlay-2"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breadcroumb-title text-center">
                                <h1>Contact</h1>
                                <h6><Link to="/">Home</Link> / Contact</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contact-section section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4">
                            <div class="contact-wrapper">
                                <div class="contact-inner">
                                    <div class="contact-headline">
                                        <h3>Get in touch</h3>
                                    </div>
                                    <div class="contact-meta-info">
                                        <div class="contact-single-info">
                                            <i class="las la-map-marker-alt"></i><h6>Address</h6>
                                            <p>Jos, Nigeria</p>
                                        </div>
                                        <div class="contact-single-info">
                                            <i class="las la-phone"></i><h6>Phone</h6>
                                            <p>Mobile: +234700000000000 </p>
                                            <p>Hotline: +234700000000000</p>
                                        </div>
                                        <div class="contact-single-info">
                                            <i class="las la-envelope"></i><h6>Email</h6>
                                            <p>Mail: hello@segullah.ng </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-8">
                            <div class="contact-form-wrap">
                                <div class="section-title">
                                    <h6>Let's Get In Touch</h6>
                                    <h2>Send Us a Message</h2>
                                </div>
                                <div class="contact_form">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6">
                                            <div>
                                                <input type="text" placeholder="Your Name" name="name" />
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6">
                                            <div>
                                                <input type="email" placeholder="Email Address" name="email" />
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6">
                                            <div>
                                                <input type="text" placeholder="Phone Number" name="phone" />
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6">
                                            <div>
                                                <input type="email" placeholder="Subject" name="Subject" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div>
                                                <textarea name="message" placeholder="Write a Message"></textarea>
                                            </div>
                                            <button type="submit" class="main-btn">send a message</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUsPage
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

function GreenInitiative() {
    return (
        <>
            <Header />
            <div class="breadcroumb-area bread-bg">
                <div class="overlay-2"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breadcroumb-title text-center">
                                <h1>Green Initiatives</h1>
                                <h6><Link to="/">Home</Link> / Green Initiatives</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="about-area section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6 wow fadeInUp" data-wow-delay=".2s">
                            <div class="about-content-wrap">
                                <div class="section-title">
                                    <h2>Vision</h2>
                                </div>
                                <div class="about-content">
                                    <div class="row">
                                        <div class="col-12 col-lg-12">
                                            <div class="about-content-left">
                                                <p class="highlight">
                                                    We envision a world where individuals are empowered with knowledge and inspired to take action towards a sustainable future. We strive to create a society that minimizes plastic waste, embraces responsible consumption, and actively participates in building a healthy planet for generations to come.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 wow fadeInUp" data-wow-delay=".2s">
                            <div class="about-content-wrap">
                                <div class="section-title">
                                    <h2>Mission</h2>
                                </div>
                                <div class="about-content">
                                    <div class="row">
                                        <div class="col-12 col-lg-12">
                                            <div class="about-content-left">
                                                <p class="highlight">
                                                    The Segullah Green Initiative is a non-profit organization dedicated to empowering communities through education and awareness programs to combat solid waste, and particularly plastic pollution, promote sustainable practices, and advocate for a greener future.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="service-area sky-bg section-padding">
                <div class="container">
                    <div class="section-title">
                        <h2>Values</h2>
                    </div>
                    <div class="row">
                        <ul>
                            <li style={{ display: 'block' }}>
                                <div class="text">
                                    <p>1. <strong>Education:</strong> We believe knowledge is the key to driving change. We provide accessible and engaging educational programs to raise awareness about environmental issues.</p>
                                </div>
                            </li>
                            <li style={{ display: 'block' }}>
                                <div class="text">
                                    <p>2. <strong>Empowerment:</strong> We empower individuals to make informed choices and take action in their everyday lives to reduce their environmental impact.</p>
                                </div>
                            </li>
                            <li style={{ display: 'block' }}>
                                <div class="text">
                                    <p>3. <strong>Collaboration:</strong> We foster partnerships with communities, educational institutions, and like-minded organizations to maximize our collective impact.</p>
                                </div>
                            </li>
                            <li style={{ display: 'block' }}>
                                <div class="text">
                                    <p>4. <strong>Innovation:</strong> We continuously explore new and creative ways to spread environmental awareness and promote sustainable solutions.</p>
                                </div>
                            </li>
                            <li style={{ display: 'block' }}>
                                <div class="text">
                                    <p>5. <strong>Sustainability:</strong> We advocate for practices that minimize environmental degradation and ensure a healthy planet for future generations.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="process-area dark-bg section-padding">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-6 col-lg-6">
                            <div class="section-title">
                                <h2 class="text-white">Our Work</h2>
                            </div>
                        </div>
                    </div>
                    <div class="process-item-wrap">
                        <div class="row">
                            <div class="mb-5 col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".2s">
                                <div class="process-single">
                                    <div class="process-icon">
                                        {/* <img src="/assets/img/process/1.png" alt="" /> */}
                                    </div>
                                    <div class="process-title">
                                        <h5>Educational Workshops</h5>
                                    </div>
                                    <div class="process-desc">
                                        <p>We offer interactive workshops for all ages, teaching participants about the negative impacts of plastic pollution, the importance of recycling, and practical tips for sustainable living.

                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-5 col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".4s">
                                <div class="process-single two">
                                    <div class="process-icon">
                                        {/* <img src="/assets/img/process/2.png" alt="" /> */}
                                    </div>
                                    <div class="process-title">
                                        <h5>Community Outreach Programs</h5>
                                    </div>
                                    <div class="process-desc">
                                        <p>We organize community clean-up drives, public awareness campaigns, and educational events to engage the public and inspire action.
                                            <br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-5 col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".6s">
                                <div class="process-single three">
                                    <div class="process-icon">
                                        {/* <img src="/assets/img/process/3.png" alt="" /> */}
                                    </div>
                                    <div class="process-title">
                                        <h5>School Partnerships</h5>
                                    </div>
                                    <div class="process-desc">
                                        <p>We collaborate with schools to develop engaging environmental education programs for students, fostering a culture of sustainability from a young age.
                                            <br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-5 col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".8s">
                                <div class="process-single four">
                                    <div class="process-icon">
                                        {/* <img src="/assets/img/process/4.png" alt="" /> */}
                                    </div>
                                    <div class="process-title">
                                        <h5>Policy Advocacy</h5>
                                    </div>
                                    <div class="process-desc">
                                        <p>We advocate for policies that promote responsible waste management, plastic reduction, and the adoption of sustainable practices.
                                            <br /><br /> <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".8s">
                                <div class="process-single five">
                                    <div class="process-icon">
                                        {/* <img src="/assets/img/process/4.png" alt="" /> */}
                                    </div>
                                    <div class="process-title">
                                        <h5>Planting of Trees</h5>
                                    </div>
                                    <div class="process-desc">
                                        <p>5.We advocate for planting trees, which helps to reduce climate change effects, and increase the resilience of ecosystems.
                                            <br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".8s">
                                <div class="process-single six">
                                    <div class="process-icon">
                                        {/* <img src="/assets/img/process/4.png" alt="" /> */}
                                    </div>
                                    <div class="process-title">
                                        <h5>Workshops and Skill Development</h5>
                                    </div>
                                    <div class="process-desc">
                                        <p>Hold workshops that demonstrate how to repurpose old tires into creative furniture pieces like ottomans, coffee tables, or even planters.

                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="choose_us section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12 wow fadeInUp" data-wow-delay=".4s">
                            <div class="choose_us_right">
                                <div class="section-title text-left">
                                    <h2>Connection to Segullah Recycling Limited</h2>
                                </div>
                                <p class="choose_us_right-text">
                                The Segullah Green Initiative is a proud affiliate of Segullah Recycling Limited. We share a commitment to environmental responsibility and believe in the power of education to create positive change. Through our combined efforts, we aim to build a circular economy for plastic waste and empower communities to embrace a sustainable future.
                                    <br />
                                    <strong>We invite you to join us!</strong>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default GreenInitiative

import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AboutPage from './pages/AboutPage';
import ContactUsPage from './pages/ContactUsPage';
import GreenInitiative from './pages/GreenInitiative';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/green-initiative" element={<GreenInitiative />} />
      </Routes>
    </Router>
  );
}

export default App;

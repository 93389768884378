import React from 'react'
import Logo from './../assets/logo.png';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
            <footer class="footer-area">
                <div class="container">
                    <div class="footer-up">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="logo">
                                    <img src={Logo} alt="ecofix-logo" style={{ height: '100px' }} />
                                </div>
                                <div class="contact-info">
                                    <p><b>Location:</b> Jos, Nigeria</p>
                                    <p><b>Phone:</b> 0700000000000</p>
                                    <p><b>E-mail:</b> info@segullahrecycling.ng</p>
                                    <p><b>Opening Hour:</b> 08.00 AM - 06.00 PM</p>
                                </div>

                            </div>
                            <div class="col-lg-5 col-md-6 com-sm-12">

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <h6>Quick Links</h6>
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                                <Link to="/about">About us</Link>
                                                <Link to="/green-initiative">Segullah Foundation</Link>
                                                <Link to="/contact">Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <h6>Services</h6>
                                        <ul>
                                            <li>
                                                <Link href="/about">PET Bottle Collection</Link>
                                                <Link href="/about">Sorting and Processing</Link>
                                                <Link href="/about">PET Recycling</Link>
                                                <Link href="/about">Product Manufacturing Potential</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="subscribe-form">
                                    <h6>Newsletter</h6>
                                    <form action="#" method="post">
                                        <input type="email" placeholder="Your email" />
                                        <button type="submit"><i class="las la-envelope"></i></button>
                                    </form>
                                    <p>Stay tuned for our latest news</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div class="footer-bottom">
                <div class="container">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-lg-3 col-12">
                            <div class="copyright-area">
                                <p class="copyright-line">© {(new Date()).getFullYear()} Segullah Recycling. All rights reserved.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <p class="privacy"><Link href="#">Terms &amp; Conditions</Link> <Link href="#">Privacy Policy</Link> </p>
                        </div>
                        <div class="col-lg-3 col-12 text-end">
                            <div class="social-area">
                                <a href="https://www.facebook.com/share/KZiTwwfECxTPX3PF/?mibextid=qi2Omg"><i class="lab la-facebook-f"></i></a>
                                <a href="https://youtube.com/@segullah-h5i?si=ZD9rUoufA8shEyZ3"><i class="lab la-youtube"></i></a>
                                <a href="https://x.com/segullahgreenf?t=vgz1391tFOdHSHij0UvzlA&s=08"><i class="lab la-twitter"></i></a>
                                <a href="https://www.instagram.com/invites/contact/?igsh=1n15hmcku93q1&utm_content=v85cqiy"><i class="lab la-instagram"></i></a>
                                <a href="https://www.linkedin.com/company/segullah-green-foundation/"><i class="lab la-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer